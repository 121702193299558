// --- Vendors ---
// --- Components ---

const singleProduit = {
    readyFn: function () {
        console.log("Single Produit ready");

        $(".header").on('click', function () {
            $(this).toggleClass('actif');
            $("section#single-produit .description-longue").slideToggle();
        });

    }
}

export { singleProduit };