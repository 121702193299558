// --- Vendors ---
import Swiper from 'swiper';

// --- Components ---
const singleDesigner = {
    readyFn: function () {
        console.log("Designer ready")
        var swiper = new Swiper('.swiper', {
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

        var swiper = new Swiper('.swiper-design', {
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
            centeredSlides: true,
            spaceBetween: 20,
        });
    }
}
export { singleDesigner };