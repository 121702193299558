// --- Vendors ---
// --- Components ---
const contact = {
    readyFn: function () {
        $("#faq .cell-faq").click(function () {
            $(".reponse", this).slideToggle("200");
            $(".question .fleche", this).toggleClass("rotated");
        });
    }
}
export { contact };