// --- Vendors ---
import Swiper from 'swiper';

// --- Components ---
const homePage = {
    readyFn: function () {
        console.log("Home page ready");

        var swiperIntro = new Swiper('.swiper-intro', {
            slidesPerView: 1,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });


        var swiperCat = new Swiper('.swiper-cat', {
            slidesPerView: 1.5,
            spaceBetween: 10,
            autoplay: {
                delay: 4000,
            },
            pagination: {
                el: '.swiper-pagination',
            },
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 20
                },
            },
        });
    }
}
export { homePage };