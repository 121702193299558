// --- Vendors ---
// --- Components ---
const video = {
    readyFn: function () {
        let self = this;
        this.video();

    },

    video: function () {
        console.log("Start vidéo");
        $("#video .cell-video").on("click", function () {
            $(".fadeout", this).fadeOut();
            $("#mavideo")[0].play();
        });
    },
}

export { video };