// --- Vendors ---
import Swiper from 'swiper';
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// --- Components ---
const pro = {
    readyFn: function () {
        console.log("pro.readyFn");
        jQueryBridget('isotope', Isotope, $);

        // Initialisation du Swiper
        var swiperCat = new Swiper('.swiper-marque', {
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 20
                },
            },
            autoplay: {
                delay: 4000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        //SEARCH ---------------------------

        $('#product-search-input').on('keyup', function () {
            var searchTerm = $(this).val();

            if (searchTerm.length >= 2) {
                $.ajax({
                    url: ajaxurl,
                    type: 'post',
                    data: {
                        action: 'product_search',
                        search_term: searchTerm
                    },
                    success: function (response) {
                        $('#search-results').html(response);
                    }
                });
            } else {
                $('#search-results').empty();
            }
        });

        //END SEARCH -------------------------

        $(".show-filtre-mobile").on("click", function () {
            $(".filtre .wrapper-select-filtre").slideToggle();
            console.log("j'appuis");
        });

        // Initialisation des grilles Isotope
        var gridProduit = $('.grid-produit').isotope({
            itemSelector: '.cell-relation',
            layoutMode: 'fitRows'
        });

        var gridFiltre = $('.grid-filtre').isotope({
            itemSelector: '.cell-relation',
            layoutMode: 'fitRows'
        });

        // Initialisation des tableaux de filtres
        var marqueFilters = [];
        var designerFilters = [];
        var categorieFilters = [];

        // Fonction pour basculer la visibilité des tableaux
        function toggleVisibility(isFiltered) {
            if (isFiltered) {
                $("#product-list").hide();
                $(".grid-load").hide();
                $("#product-list-filtre").show();
                $(".grid-load-filtre").show();
                $(".selected-filter").show();
            } else {
                $("#product-list").show();
                $(".grid-load").show();
                $("#product-list-filtre").hide();
                $(".grid-load-filtre").hide();
                $(".selected-filter").hide();
            }
        }

        // Fonction pour appliquer les filtres combinés
        function applyFilters() {
            var allFilters = [];

            if (marqueFilters.length === 0) marqueFilters = [""];
            if (designerFilters.length === 0) designerFilters = [""];
            if (categorieFilters.length === 0) categorieFilters = [""];

            marqueFilters.forEach(function (marque) {
                designerFilters.forEach(function (designer) {
                    categorieFilters.forEach(function (categorie) {
                        allFilters.push(marque + designer + categorie);
                    });
                });
            });

            var finalFilter = allFilters.join(", ");
            gridFiltre.isotope({
                filter: finalFilter
            });

            console.log("marqueFilters:", marqueFilters);
            console.log("designerFilters:", designerFilters);
            console.log("categorieFilters:", categorieFilters);
            console.log("finalFilter: " + finalFilter);

            // Mise à jour du texte du filtre sélectionné
            function processFilters(filters) {
                if (filters.length === 0) {
                    return '';
                }
                return filters.map(filter => filter.replace(/\./g, ' ')).join(', ');
            }

            var selectedFinalFilter = '';

            if (marqueFilters[0] != [""] || marqueFilters[0] != "") {
                selectedFinalFilter += processFilters(marqueFilters);
            }
            if (designerFilters[0] != [""] || designerFilters[0] != "") {
                selectedFinalFilter += (selectedFinalFilter.length > 0 && designerFilters[0] != [""] ? ', ' : '') + processFilters(designerFilters);
            }
            if (categorieFilters[0] != [""] || categorieFilters[0] != "") {
                console.log("selectedFinalFilter.length" + selectedFinalFilter.length);
                console.log("categorieFilters[0]" + categorieFilters[0]);
                selectedFinalFilter += (selectedFinalFilter.length > 0 ? ', ' : '') + processFilters(categorieFilters);
            }

            console.log("selectedFinalFilter : " + selectedFinalFilter);

            if (selectedFinalFilter.indexOf(',') !== -1) {
                $(".selected-filter").text("Filtres sélectionnés : " + selectedFinalFilter);
            } else {
                $(".selected-filter").text("Filtre sélectionné : " + selectedFinalFilter);
            }

        }

        // Gérer la visibilité des éléments de filtrage
        $(".save-button").on("click", function () {
            toggleVisibility(true);
        });

        //MARQUE --------------------------
        // Gestion des filtres de marque
        $("#button-marque").on('click', function () {
            $(".visibility-marque").show();
            $(".visibility-designer").hide();
            $(".visibility-categorie").hide();
        });

        $("#wrapper-marque").on("mouseleave", function () {
            $(".visibility-marque").hide();
        });

        $(".marque").on("click", function () {
            $(this).toggleClass("actif");
        });

        $("#save-marque").on("click", function () {
            marqueFilters = [];
            $("#wrapper-marque .marque.actif").each(function () {
                var marque = $(this).attr("data-marque");
                marqueFilters.push(marque);
            });

            $(".container-designers .designer").addClass("not-selected");
            $(".container-categorie .categorie").addClass("not-selected");

            $(".container-designers .designer").each(function () {
                var designerCategorie = $(this).attr("data-marque");
                var designerCategories = designerCategorie.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = designerCategories.some(cat => marqueFilters.includes("." + cat));

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            $(".container-categorie .categorie").each(function () {
                var categorieMarque = $(this).attr("data-marque");
                var categorieMarques = categorieMarque.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = categorieMarques.some(cat => marqueFilters.includes("." + cat));
                console.log("MATCH FOUND = " + matchFound);
                console.log("MarqueFilters = " + marqueFilters);
                console.log("CatgoriesMarques = " + categorieMarques);

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            applyFilters();
        });

        $("#delete-marque").on("click", function () {
            $("#wrapper-marque .marque.actif").removeClass("actif");
            marqueFilters = [];
            $(".container-marques .marque").removeClass("not-selected");
            $(".container-designers .designer").removeClass("not-selected");
            $(".container-categorie .categorie").removeClass("not-selected");
            applyFilters();
        });

        //DESIGNER ------------------------
        // Gestion des filtres de designer
        $("#button-designer").on('click', function () {
            $(".visibility-designer").show();
            $(".visibility-marque").hide();
            $(".visibility-categorie").hide();
        });

        $("#wrapper-designer").on("mouseleave", function () {
            $(".visibility-designer").hide();
        });

        $(".designer").on("click", function () {
            $(this).toggleClass("actif");
        });

        $("#save-designer").on("click", function () {
            designerFilters = [];
            $("#wrapper-designer .designer.actif").each(function () {
                var designer = $(this).attr("data-designer");
                designerFilters.push(designer);
            });

            $(".container-marques .marque").addClass("not-selected");
            $(".container-categorie .categorie").addClass("not-selected");

            $(".container-marques .marque").each(function () {
                var marqueDesigner = $(this).attr("data-designer");
                var marqueDesigners = marqueDesigner.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = marqueDesigners.some(cat => designerFilters.includes("." + cat));

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            $(".container-categorie .categorie").each(function () {
                var categorieDesigner = $(this).attr("data-designer");
                var categorieDesigners = categorieDesigner.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = categorieDesigners.some(cat => designerFilters.includes("." + cat));

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            applyFilters();
        });

        $("#delete-designer").on("click", function () {
            $("#wrapper-designer .designer.actif").removeClass("actif");
            designerFilters = [];
            $(".container-marques .marque").removeClass("not-selected");
            $(".container-designers .designer").removeClass("not-selected");
            $(".container-categorie .categorie").removeClass("not-selected");
            applyFilters();
        });

        //CATEGORIE ----------------------
        // Gestion des filtres de catégorie
        $("#button-categorie").on('click', function () {
            $(".visibility-categorie").show();
            $(".visibility-marque").hide();
            $(".visibility-designer").hide();
        });

        $("#wrapper-categorie").on("mouseleave", function () {
            $(".visibility-categorie").hide();
        });

        $(".categorie").on("click", function () {
            $(this).toggleClass("actif");
        });

        $("#save-categorie").on("click", function () {
            categorieFilters = [];
            $("#wrapper-categorie .categorie.actif").each(function () {
                var categorie = $(this).attr("data-categorie");
                categorieFilters.push(categorie);
            });

            $(".container-marques .marque").addClass("not-selected");
            $(".container-designers .designer").addClass("not-selected");

            $(".container-marques .marque").each(function () {
                var marqueCategorie = $(this).attr("data-categorie");
                var marqueCategories = marqueCategorie.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = marqueCategories.some(cat => categorieFilters.includes("." + cat));

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            $(".container-designers .designer").each(function () {
                var designerCategorie = $(this).attr("data-categorie");
                var designerCategories = designerCategorie.split(" ").map(cat => cat.replace(/^\./, ''));
                var matchFound = designerCategories.some(cat => categorieFilters.includes("." + cat));

                if (matchFound) {
                    $(this).removeClass("not-selected");
                }
            });

            applyFilters();
        });

        $("#delete-categorie").on("click", function () {
            $("#wrapper-categorie .categorie.actif").removeClass("actif");
            categorieFilters = [];
            $(".container-marques .marque").removeClass("not-selected");
            $(".container-designers .designer").removeClass("not-selected");
            $(".container-categorie .categorie").removeClass("not-selected");
            applyFilters();
        });

        // BOUTON SUPPRIMER LESA FILTRES -----------------------
        // Détection des filtres supprimés
        $(".delete-button").on("click", function () {
            console.log("marqueFilters:", marqueFilters);
            console.log("designerFilters:", designerFilters);
            console.log("categorieFilters:", categorieFilters);

            marqueFilters = marqueFilters.filter(filter => filter !== "");
            designerFilters = designerFilters.filter(filter => filter !== "");
            categorieFilters = categorieFilters.filter(filter => filter !== "");

            if (marqueFilters.length === 0 && designerFilters.length === 0 && categorieFilters.length === 0) {
                console.log("Tout est vide, retour à l'affichage original");
                toggleVisibility(false);
                $(".selected-filter").text("");
                $(".container-marques .marque").removeClass("not-selected");
                $(".container-designers .designer").removeClass("not-selected");
                $(".container-categories .categorie").removeClass("not-selected");
            }
        });

        $("#button-delete-all").on("click", function () {
            $(".visibility-marque").hide();
            $(".visibility-designer").hide();
            $(".visibility-categorie").hide();
            $("#wrapper-marque .marque.actif").removeClass("actif");
            $("#wrapper-designer .designer.actif").removeClass("actif");
            $("#wrapper-categorie .categorie.actif").removeClass("actif");
            marqueFilters = [];
            designerFilters = [];
            categorieFilters = [];
            $(".selected-filter").text("");
            toggleVisibility(false);
            $(".container-marques .marque").removeClass("not-selected");
            $(".container-designers .designer").removeClass("not-selected");
            $(".container-categorie .categorie").removeClass("not-selected");
            applyFilters();
        });

        // Gestion des clics sur le document pour masquer les divs sauf celles spécifiques
        $(document).on('click', function (event) {
            const $target = $(event.target);
            const isClickInsideAnyDiv = [
                '.container-marques',
                '.container-designers',
                '.container-categorie'
            ].some(selector => $target.closest(selector).length > 0);

            if (!isClickInsideAnyDiv) {
                $('.visibility-marque, .visibility-designer, .visibility-categorie').hide();
            }
        });

        // Empêcher la propagation des clics à l'intérieur des divs spécifiques
        $('.visibility-marque, .visibility-designer, .visibility-categorie').on('click', function (event) {
            event.stopPropagation();
        });

    },
}
export { pro };
