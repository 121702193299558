// --- Vendors ---
import Swiper from 'swiper';
import Isotope from 'isotope-layout';
var jQueryBridget = require('jquery-bridget');

// --- Components ---
const marque = {
    readyFn: function () {
        console.log("marque.readyFn")
        jQueryBridget('isotope', Isotope, $);

        var swiperCat = new Swiper('.swiper-marque', {
            slidesPerView: 2,
            spaceBetween: 20,
            breakpoints: {
                // when window width is >= 320px
                1200: {
                    slidesPerView: 4,
                    spaceBetween: 20
                },
            },
            autoplay: {
                delay: 4000,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        var gridProduit = $('.grid-produit').isotope({
            itemSelector: '.cell-relation',
            layoutMode: 'fitRows'
        });


        /* var slugMarque = $("#main-content").attr("data-slug");

        if (slugMarque && slugMarque.length > 0) {
            $("#filtre-marque").val(slugMarque); // Sélectionne la valeur
            $("#filtre-marque option[value='" + slugMarque + "']").prop('selected', true); // Sélectionne visuellement l'option
            var filterValue = slugMarque;
            gridProduit.isotope({
                filter: filterValue
            });
        } */

        var filterValue;


        $("#filtre-marque").on("change", function () {
            $(this).toggleClass("actif");
            $("#filtre-designer").prop('selectedIndex', 0);
            $("#filtre-categorie").prop('selectedIndex', 0);
            var filterValue = $(this).val();
            gridProduit.isotope({
                filter: filterValue
            });
            console.log("filterValues : " + filterValue);
        });

        $("#filtre-designer").on("change", function () {
            $(this).toggleClass("actif");
            $("#filtre-marque").prop('selectedIndex', 0);
            $("#filtre-categorie").prop('selectedIndex', 0);
            var filterValue = $(this).val();
            gridProduit.isotope({
                filter: filterValue
            });
        });

        $("#filtre-categorie").on("change", function () {
            $(this).toggleClass("actif");
            $("#filtre-marque").prop('selectedIndex', 0);
            $("#filtre-designer").prop('selectedIndex', 0);
            var filterValue = $(this).val();
            gridProduit.isotope({
                filter: filterValue
            });
        });

    },

}
export { marque };