const search = {
    readyFn: function () {
        $('#product-search-input').on('keyup', function () {
            var searchTerm = $(this).val();

            if (searchTerm.length >= 2) {
                $.ajax({
                    url: ajaxurl,
                    type: 'post',
                    data: {
                        action: 'product_search',
                        search_term: searchTerm
                    },
                    success: function (response) {
                        $('#search-results').html(response).show();
                    }
                });
            } else {
                $('#search-results').empty().hide();
            }
        });

        // Fermer les résultats de recherche lorsqu'on clique ailleurs
        $(document).on('click', function (event) {
            if (!$(event.target).closest('#product-search-form').length) {
                $('#search-results').hide();
            }
        });

        // Empêcher la propagation de l'événement de clic pour le champ de recherche
        $('#product-search-form').on('click', function (event) {
            event.stopPropagation();
        });

        // Empêcher le rechargement de la page lors de l'appui sur la touche Entrée
        $('#product-search-form').on('keydown', function (event) {
            if (event.key === "Enter") {
                event.preventDefault();
            }
        });
    }
}

export { search };